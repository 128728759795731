import React from 'react'
import useRouter from 'use-react-router';

import { ListItem, ListItemText } from "@material-ui/core";

import { TestUnit } from '../../lib/class/TestUnit';

export interface TestUnitRowI{
    value:TestUnit;
    style?:any;
}

export function TestUnitRow(props:TestUnitRowI){
    const {value,style} = props;
    const router = useRouter();

    const styles = {
        row:Object.assign({
            opacity:value.deleted?0.25:1,
            height:84,
            overflow:"hidden"
        },style)
    };

    return <ListItem style={styles.row} button divider onClick={() => router.history.push("/entity/"+value.id)} >
        <ListItemText primary={value.toString()} secondary={`${value.getNextCalibration().format('DD/MM/YY')} ${value.serialNumber} ${value.nataReference}`} />
    </ListItem>
}