import React, { useContext, useEffect, useReducer } from 'react';
import useRouter from 'use-react-router';
import {GlobalContext} from '../../ui/withGlobalContext';

import {Card, CardContent,Theme, Divider, Typography,Table, TableRow, TableCell, TableBody, TableHead, Chip, IconButton, Button, CardHeader, CardActions, Box} from "@material-ui/core";
import { Job } from '../../lib/class/Job';
import { Task, TaskJ, TaskState, TaskStateToColor, TaskStateToString, TaskStatus } from '../../lib/class/Task';
import { Tech } from '../../lib/class/Tech';
import { Add, Description, Face, ListAlt, Search, Warning, Settings as SettingsIcon, FileCopy } from '@material-ui/icons';
import { TaskType } from '../../lib/class/TaskType';
import { ColorChip } from '../ColorChip';
import { EntityField, ReadOnlyField } from '../Fields';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import { downloadTextFile } from '../../lib/download';
import { CssStyles } from '../../css-types';


export interface JobCardI{
    value:Job;
    extended:boolean;
}

export function JobCard(props:JobCardI){
    const {value,extended} = props;

    const router = useRouter();
    const global = useContext(GlobalContext);
    const { theme, project, ui, ds } = global;


    const forceUpdate = useReducer((x) => x + 1, 0)[1];
    useEffect(() => ds.sub(job.id,"*",forceUpdate),[]);


    const sm = useMediaQuery((theme:Theme) => theme.breakpoints.down('sm'));

    const styles = {}

    const job = value;
    // job.processTasks();
    job.processIssues();
    const type = job.getType();
    const tasks = type.getTasks();

  
    async function addLabourTask(){
        const taskD:TaskJ = {
            id:0,
            type:"Task",
            deleted:false,
            jobID:job.id,
            typeID:6,
            scheduleTimestamp:null,
            startTimestamp:null,
            endTimestamp:null,
            techID:null,
            status:TaskStatus.Pending,
            ignoreValidation:false
        };
        const result:Task = await ds.createEntity(taskD);
        await result.createMissingData();
        // await ds.save();
        result.processJob();
        // job.processTasks();
        router.history.push(`/task/${result.id}`);
    }

    function viewPaperwork(){
        router.history.push(`/paperwork/${value.id}`);
    }
    function viewNativePaperwork(){
        router.history.push(`/nativePaperwork/${value.id}`);
    }

    function downloadTestResults(){
        try{
            const site = job.getSite();
            // const ctTesting:TaskType = ds.db.filter({type:"TaskType",name:"Meter Test"})[0];
            // console.log(ctTesting);
            const task = job.getMainCompletedTask();
            
            console.log('download',task);
            
            const files = task.getTestResultsFiles();
            for(let file of files){
                downloadTextFile(file.data, file.filename);
            }

        }catch(E){
            console.error(E);
            alert((E as any).toString());
        }
    }

    function nav(link:string){
        router.history.push(link)
    }


    async function duplicateJob(){
        const duped = await job.duplicate();
        console.log(duped);
        router.history.push(`/job/${duped.id}`);
        // console.log(site.toString())
    }



    return  <Card>
        <CardContent>
            <Box display="flex" p={1} >
                <Box flexGrow={1}><Typography variant="h6" >Job</Typography></Box>
                {ds.db.getMe().isSudo()?<Box><IconButton onClick={() => router.history.push("/entity/"+value.id)} ><SettingsIcon /></IconButton> </Box>:null}
            </Box>
        </CardContent>
        <Divider/>
        <CardContent>
            <ReadOnlyField label="Job Type"  value={value.getType().toString()}  />
            <ReadOnlyField label="Period"  value={`${value.getStartDate().format("DD/MM/YY")} - ${value.getDueDate().format("DD/MM/YY")}`}  />
            <br/>
            <br/>
            <JobDataTable job={job} />


            {extended?<React.Fragment>

            <br/>
            <br/>
            <Typography variant="h6" >Timeline</Typography>
            {sm?<JobTimelineCards job={job} />:<JobTimeline job={job} />}
            
                <br/>
                <br/>
                <Typography variant="h6" >Actions</Typography>
                <br/>

                <EntityField label="Sent" name="sent" type="checkbox" entity={value} />
                <EntityField label="Requires Schedule" name="requiresSchedule" type="checkbox" entity={value} />
    

                <br/>
                <br/>


          
                <Button onClick={() => nav(`/job/${job.id}/form`)} color="primary" variant="outlined" fullWidth><Description /> Generate Paperwork</Button>
                
                <br/>
                <br/>
                <Button onClick={downloadTestResults} color="primary" variant="outlined" fullWidth><ListAlt /> Download Test Results</Button>
                <br/>
                <br/>
                <Button onClick={duplicateJob} color="primary" variant="outlined" fullWidth><FileCopy /> Duplicate Job</Button>
          
          
            </React.Fragment>:null}
            </CardContent>
        </Card>
}

    //   <Button onClick={addLabourTask} color="primary" variant="outlined" fullWidth><Add /> Labour Task</Button>
    //             <br/>
    //             <br/>



interface JobDataTableI{
    job:Job;
}

function JobDataTable(props:JobDataTableI){
    const {job} = props;

    // console.log(job);

    const jobType = job.getType();

    // console.log(jobType);
    const datas = jobType.getData();
    // console.log(datas);

    const styles:CssStyles = {
        title: {
            fontWeight:"bold"
        },
        warning:{
            fontWeight:"bold",
            color:'red',
            fontSize: 16

        },
        
    }

    let isTestButCtMeter = false;
    try{
        if(jobType.id === 198){
            let test = job.getJobDataItemValueString("Meter Model");
            if(test.toString && test.toString() === "450") isTestButCtMeter = true
        }
    }catch{


    }


    console.log(isTestButCtMeter)

    return <div>
        <Typography variant="h6" >Job Attributes</Typography>
        {datas.map( (data) => {
            let value = "";
            try{
                const item = job.getJobDataItem(data);
                //console.log(item);
                //console.log(item.getDataType().getDataType());
                if(item.getDataType().getDataType().name == "id"){
                    // console.log(item);
                    // console.log("link item",item.getLink());
                    value = item.getLink().toString();
                }else{
                    value = item.toString();
                }
            }catch(E){
                console.log(E);
            }
            return  <ReadOnlyField key={data.id} label={data.name}  value={value}  />
        })} 


        {isTestButCtMeter && <div style={styles.warning}>
            Caution: 450 is a CT meter Test
        </div>}

    </div>
}




interface JobTimelineI{
    job:Job;
}


function JobTimeline(props:JobTimelineI){
    const {job} = props;

    const router = useRouter();
    const global = useContext(GlobalContext);
    const { theme, project, ui, ds } = global;

    const styles:CssStyles = {
        title:{
            //width:250,
            fontWeight:"bold"
        },
        time:{
            width:150
        },
        icon:{
            display: "inline-flex",
            verticalAlign: "middle"
        },
    }




    const tasks = job.getTasks().sort( (a,b) => a.getTimestamp().diff(b.getTimestamp()));
    const unplanned = job.getUnplannedTasks();


    function navTask(task:Task){

        router.history.push(`/task/${task.id}`);
    }

    async function plan(taskType:TaskType){
        const me = ds.db.getMe();
        const techID:number|null = me.techID;


        const t:TaskJ = {
            id:0,
            type:"Task",
            deleted:false,
            jobID:job.id,
            typeID:taskType.id,
            scheduleTimestamp:null,
            startTimestamp:null,
            endTimestamp:null,
            techID:techID,
            status:TaskStatus.Pending,
            ignoreValidation:false
        }

        const task:Task = await ds.createEntity(t); //( {type:t.type} ,s );
        await task.createMissingData();
        // await ds.save();
        task.processJob();
       // await ds.save();
        router.history.push(`/task/${task.id}`);

    }



    return <div>
        <Table>
        <TableHead>
            <TableRow>
                <TableCell style={styles.title}>Time</TableCell>
                <TableCell style={styles.title}>Task</TableCell>
                <TableCell style={styles.title}>Tech</TableCell>
                <TableCell style={styles.title}>Status</TableCell>
                <TableCell style={styles.title}>Action</TableCell>
            </TableRow>
        </TableHead>


        <TableBody>
            {tasks.map( task => {
                let tech:Tech|null;
                 try{
                     tech = task.getTech();
                 }catch(E){
                     tech = null;
                 }
                return <TableRow hover key={task.id} onClick={() => navTask(task)} >
                    <TableCell  style={styles.time}>{task.getTimestamp().format("DD/MM HH:mm")}</TableCell>
                    <TableCell>{task.getType().toString()}</TableCell>
                    <TableCell>{tech==null?null:<TechChip value={tech} />}</TableCell>
                    <TableCell> <TaskStatusChip value={task} /> {task.hasIssues()?<Warning style={styles.icon} color="secondary" fontSize="small"  />:null} </TableCell>
                        <TableCell> <IconButton color="primary" size="small"><Search /></IconButton></TableCell>
                </TableRow> 
            })}

                {unplanned.map( taskType => <TableRow  key={taskType.id}>
                    <TableCell  style={styles.time}></TableCell>
                    <TableCell>{taskType.toString()}</TableCell>
                    <TableCell></TableCell>
                    <TableCell> <TaskStatusChip value={null}  /> </TableCell>
                    <TableCell><IconButton color="primary" size="small" onClick={() => {plan(taskType)}}><Add /></IconButton></TableCell>
                </TableRow> 
                )}


            </TableBody>
        </Table>

    </div>





}


interface TaskStatusChipI{
    value:Task|null
    onClick?:()=>void;
}

function TaskStatusChip(props:TaskStatusChipI){
    const {value,onClick} = props;


    let state:TaskState; 
    if(value == null)
        state = TaskState.Unplanned;
    else
        state = value.getState();

    const string = TaskStateToString(state);
    const color = TaskStateToColor(state);

    return <ColorChip label={string} color={color} onClick={onClick} />
    
}



interface TechChipI{
    value:Tech
}

function TechChip(props:TechChipI){
    const {value} = props;

    return  <Chip icon={<Face />} label={value.toString()} clickable color="default" />
}


/*
onDelete={handleDelete}
    deleteIcon={<DoneIcon />}
    */









interface JobTimelineI{
    job:Job;
}


function JobTimelineCards(props:JobTimelineI){
    const {job} = props;

    const router = useRouter();
    const global = useContext(GlobalContext);
    const { theme, project, ui, ds } = global;

    const styles = {
        title:{
            //width:250,
            fontWeight:"bold"
        },
        time:{
            width:150
        },
        icon:{
            display: "inline-flex",
            verticalAlign: "middle"
        },
        card:{
            marginBottom:10
        }
    }




    const tasks = job.getTasks().sort( (a,b) => a.getTimestamp().diff(b.getTimestamp()));
    const unplanned = job.getUnplannedTasks();


    function navTask(task:Task){

        router.history.push(`/task/${task.id}`);
    }

    async function plan(taskType:TaskType){
        const me = ds.db.getMe();
        const techID:number|null = me.techID;


        const t:TaskJ = {
            id:0,
            type:"Task",
            deleted:false,
            jobID:job.id,
            typeID:taskType.id,
            scheduleTimestamp:null,
            startTimestamp:null,
            endTimestamp:null,
            techID:techID,
            status:TaskStatus.Pending,
            ignoreValidation:false
        }

        const task:Task = await ds.createEntity(t); //( {type:t.type} ,s );
        await task.createMissingData();
        // await ds.save();
        task.processJob();
        router.history.push(`/task/${task.id}`);

    }



    return <div>


            {tasks.map( task => {
                let tech:Tech|null;
                 try{
                     tech = task.getTech();
                 }catch(E){
                     tech = null;
                 }
                return <Card key={task.id} style={styles.card} >
                    <CardHeader avatar=" " action={<IconButton onClick={() => navTask(task)} color="primary" ><Search /></IconButton>} title={task.getType().toString()} subheader={task.getTimestamp().format("DD/MM HH:mm")}/>
                    <CardContent>
                   
                    <TaskStatusChip value={task} />
                    {tech==null?null:<TechChip value={tech} />}
                    {task.hasIssues()?<Warning style={styles.icon} color="secondary" fontSize="small"  />:null}
                    </CardContent>
                    <CardActions disableSpacing>
                    </CardActions>
                </Card>
            })}

               
{unplanned.map( taskType => <Card key={taskType.id}  style={styles.card} >
    <CardHeader avatar=" " action={<IconButton color="primary" onClick={() => {plan(taskType)}}><Add /></IconButton>} title={taskType.toString()} subheader={"Required"}/>
    <CardContent>

    <TaskStatusChip value={null}  /> 

    </CardContent>
    <CardActions disableSpacing>
    </CardActions>
    </Card>)}


    </div>





}


/*  </CardContent>
                    <TableCell  style={styles.time}>{task.getTimestamp().format("DD/MM HH:mm")}</TableCell>
                    <TableCell>{task.getType().toString()}</TableCell>
                    <TableCell>{tech==null?null:<TechChip value={tech} />}</TableCell>
                    <TableCell> <TaskStatusChip value={task} /> {task.hasIssues()?<Warning style={styles.icon} color="secondary" fontSize="small"  />:null} </TableCell>
                        <TableCell></TableCell>
                </TableRow> 
                
               </Card> */


/* {unplanned.map( taskType => <TableRow  key={taskType.id}>
                    <TableCell  style={styles.time}></TableCell>
                    <TableCell>{taskType.toString()}</TableCell>
                    <TableCell></TableCell>
                    <TableCell> <TaskStatusChip value={null}  /> </TableCell>
                    <TableCell><IconButton color="primary" size="small" onClick={() => {plan(taskType)}}><Add /></IconButton></TableCell>
                </TableRow> 
                )}*/