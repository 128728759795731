import moment from "moment";
import { Job, JobJ } from '../../../lib/class/Job';
import { Site, SiteJ } from '../../../lib/class/Site';
import { JobData, JobDataI } from '../../../lib/class/JobData';
import { Datom, getsertDatom } from '../../../lib/class/Datom';
import { JobTypeData } from '../../../lib/class/JobTypeData';
import { rowHasKeys } from '../utils';
import { Datastore } from '../../../lib/Datastore';
import { MeteringProvider } from '../../../lib/class/MeteringProvider';
import { JobType } from '../../../lib/class/JobType';
import { isPlainObject } from "../../../lib/plain-object";




export interface MondoCtRow {
    "StartDate":string;
    "EndDate":string;
    "Issue_To":string;
    "Job":string;
    "Primary Secondary":string;
    "Service Order":string;
    "NMI":string;
    "Testing Description":string;
    "Customer Name":string;
    "Customer Phone":string;
    "MailPostal":string;
    "MailStreet1":string;
    "MailStreet2":string;
    "MailCity":string;
    "MailState":string;
    "MailPostCode":string;
    "Addr1Street":string;
    "Addr1Street2":string;
    "Addr1City":string;
    "Addr1PostCode":string;
    "Addr1State":string;
    "Division":string;
    "Location details":string;
    "Hazard Details 1":string;
    "Hazard Details 2":string;
    "Hazard Details 3":string;
    "Hazard Severity":string;
    "Meter Installation type":string;
    "Meter make":string;
    "Meter prefix":string;
    "Meter program":string;
    "Meter number":string;
    "Life support flag":string;
    "CT1_Serial":string;
    "CT1_Type":string;
    "CT2_Serial":string;
    "CT2_Type":string;
    "CT3_Serial":string;
    "CT3_Type":string;
    "CT_Ratio":string;
    "PowerOffMins":string;
    "4GRegion":string;
    "Company":string;
    }
    
    
    
    const mondoCtCsvKeys = [
        "StartDate",
        "EndDate",
        "Issue_To",
        "Job",
        "Primary Secondary",
        "Service Order",
        "NMI",
        "Testing Description",
        "Customer Name",
        "Customer Phone",
        "MailPostal",
        "MailStreet1",
        "MailStreet2",
        "MailCity",
        "MailState",
        "MailPostCode",
        "Addr1Street",
        "Addr1Street2",
        "Addr1City",
        "Addr1PostCode",
        "Addr1State",
        "Division",
        "Location details",
        "Hazard Details 1",
        "Hazard Details 2",
        "Hazard Details 3",
        "Hazard Severity",
        "Meter Installation type",
        "Meter make",
        "Meter prefix",
        "Meter program",
        "Meter number",
        "Life support flag",
        "CT1_Serial",
        "CT1_Type",
        "CT2_Serial",
        "CT2_Type",
        "CT3_Serial",
        "CT3_Type",
        "CT_Ratio",
        "PowerOffMins",
        "4GRegion",
        "Company"
    ]

export function isMondoCtRow(row:unknown):row is MondoCtRow{
    if(!isPlainObject(row)) return false;
    if(!('Job' in row)) return false;
    if(!(typeof row.Job === "string") )return false;
    if(!row.Job.startsWith("CT Test")) return false;
   return rowHasKeys('mondo-ct',mondoCtCsvKeys, row)
}

export async function importMondoCtRow(ds:Datastore, row:MondoCtRow) {
    const site = await importSite(ds,row);
    const job = await importMondoCtJob(ds,site,row);
    console.log("site",site);
    console.log("job",job)
    return job;
}




async function importSite(ds:Datastore, row:MondoCtRow):Promise<Site>{

    const mpb = ds.db.find<MeteringProvider>({type:"MeteringProvider",name:"AusNet"});
    const s:SiteJ = {
        id:0,
        type:"Site",
        deleted:false,
        nmi:row.NMI,
        customerName:row["Customer Name"],
        customerContactName:row["Customer Name"],
        customerPhone:row["Customer Phone"],
        address: [row.Addr1Street, row.Addr1Street2, row.Addr1City, row.Addr1State].filter(l => l !== "").join(" "),
        mpID:mpb.id,
        nmiAmend:null,
        customerNameAmend:null,
        customerContactNameAmend:null,
        customerPhoneAmend:null,
        addressAmend:null,
        location:null,
        lifeSupport:row["Life support flag"] !== "NO",
        meterBoxLocation:row["Location details"],
        meterBoxLocationAmend:null,
        hazard: row["Hazard Details 1"],
        severity: row["Hazard Severity"]
    }
    console.log(s.address);
    const site:Site = await ds.upsert({type:s.type,nmi:s.nmi}, s);
    console.log(site.address);
    return site;
}



export async function importMondoCtJob(ds:Datastore, site:Site, row:MondoCtRow) {


    const jobType = ds.db.find<JobType>({type:"JobType",name:"Mondo CT Testing"});
    const j:JobJ = {
        id:0,
        type:"Job",
        deleted:false,
        siteID:site.id,
        typeID:jobType.id,
        startDate: moment(row['StartDate'],"DD/MM/YYYY").valueOf(), 
        dueDate: moment(row['EndDate'],"DD/MM/YYYY").valueOf(), 
        sent:false,
        requiresSchedule:false,
        ignore:false
    }

 
    const meterModelDatom = await getsertDatom(ds,"Meter Model",row["Meter prefix"],true);


    const jobData:JobUpdateDataI = {
        "Work Order":'',
        "Record ID":row['Service Order'],
        "Constant K":await getsertDatom(ds,"Constant K",""),
        "CT Ratio":await getsertDatom(ds,"CT Ratio",processCTRatio(row.CT_Ratio)),
        "Meter Number":row['Meter number'].replace(",",""),
        "Meter Model": meterModelDatom,
        "Meter Current Rating":await getsertDatom(ds,"Meter Current Rating","5-15A"), 
        "CT Red Phase Serial Number":row['CT1_Serial'],
        "CT White Phase Serial Number":row['CT2_Serial'],
        "CT Blue Phase Serial Number":row['CT3_Serial'],
        "Program":row["Meter program"],
        "CT Type":row["CT1_Type"]
    }

    const job:Job = await upsertJob(ds, j, jobData);
    return job;
}

interface JobUpdateDataI{
    "Work Order":string;
    "Record ID":string;
    "Constant K":Datom|null;
    "CT Ratio":Datom|null;
    "Meter Number":string;
    "Meter Model":Datom|null;
    "Meter Current Rating":Datom|null;
    "CT Red Phase Serial Number":string;
    "CT White Phase Serial Number":string;
    "CT Blue Phase Serial Number":string;
    "Program":string;
    "CT Type":string;


}




    async function upsertJob(ds:Datastore, data:JobJ, jobData:JobUpdateDataI){

        // find any jobdata
        const workOrderType = ds.db.find<JobTypeData>({type:"JobTypeData",name:"Record ID"});

        const matchingDatas:JobData[] = ds.db.filter({ type:"JobData", dataID:workOrderType.id, value:jobData["Record ID"] });
        const jobs:Job[] = matchingDatas.map( d => d.getJob() );

        let job:Job;
        if(jobs.length === 0){
            console.log("not found creating")
            job = await ds.createEntity(data);
        } else if(jobs.length > 1){
            throw new Error("Multiple matches");
        }else{
            console.log("found match")
            job = jobs[0];
            ds.updateEntity(job,data);
        }


        //run through job data and upsert
        
        const keys = Object.keys(jobData) as (keyof JobUpdateDataI)[]
        for(const key of keys){
            const value = jobData[key];
            const dataTypes = ds.db.filter({type:"JobTypeData",name:key, jobTypeID:job.typeID}) as JobTypeData[];
            if(dataTypes.length === 0)
                throw new Error("dataType no matches");

            const dataType = dataTypes[0];
            const t = dataType.getDataType();
            
            const jd:JobDataI = {
                id:0,
                type:"JobData",
                deleted:false,
                jobID:job.id,
                dataID:dataType.id,
                value:null,
                valueID:null
            }

            if( t.name === "id" ){
                if(value != null && typeof value != "string"){
                    jd.valueID = value.id;
                }

            }else{
                jd.value = value
            }

            await ds.upsert({ type:"JobData", dataID:dataType.id, jobID:job.id },jd);

        }

        return job
    }


    function processCTRatio(value:string){
        // const re = new RegExp('(\\d+\\/\\d+)');
        // const match = re.exec(value);
        return value;
    }

    function processKFactor(value:string){
        if(value === "0")
            return "";
        else 
            return value;
    }



